import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidatorFn,
	Validators,
} from "@angular/forms";
import { forkJoin, Subject, Subscription } from "rxjs";
import { concatMap, debounceTime, exhaustMap, take, takeUntil, takeWhile } from "rxjs/operators";
import { ProductosService } from "src/app/business/productos.service";
import { VentaService } from "src/app/core/services/venta.service";
import { Cliente } from "src/app/models/cliente.model";
import { ModalService } from "src/app/shared/modal.service";
import { PagerService } from "src/app/shared/pager.service";
import { TranslateService } from "src/app/shared/translate.service";
import { UserService } from "src/app/user/user.service";
import { UtilsService } from "../../../utils.service";
import { GlobalService } from "../../../../shared/services/global/global.service";
import { HttpBodyGetProvincias } from "src/app/business/productos.service.interface";
import { SnackbarInfoService } from "src/app/shared/services/alerts/snackbar-info/snackbar-info.service";
import { InitService } from "src/app/core/init/init.service";
import { ProcedenciaValues } from "../../interfaces/resume.interface";

@Component({
	selector: "app-formulario-cliente",
	templateUrl: "./formulario-cliente.component.html",
	styleUrls: ["./formulario-cliente.component.scss"],
})
export class FormularioClienteComponent implements OnInit, OnDestroy {
	private ngUnsubscribe = new Subject();
	modelChanged: Subject<string> = new Subject<string>();
	isLoading = true;
	pager: any = {};
	pagedItems: any[];
	totalPages: number;
	datosForm: FormGroup;
	campos;
	abrir = [];
	clientes = [];
	campo;
	ilogueo = 0;
	cargado: Boolean = false;

	// paisSeleccionado; //Yaribel 20210531 Añadimos variable para obtener el Codigo del pais seleccionado
	user;
	@Input() paises: any;
	@Input() provincias: any;
	@Input() provinciaCliente: string;
	@Output() datosClienteForm = new EventEmitter<FormGroup>();
	@Output() datosProcedenciaForm = new EventEmitter<ProcedenciaValues>();

	paisesV2 = [];
	provinciasV2 = [];
	ciudadesV2 = [];

	lastSelectedCodigoPais = null;
	lastSelectedProvincia = null;

	clientByAdmin = null;
	noEsAdmin = true;

	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
		private translateService: TranslateService,
		private pagerService: PagerService,
		private ventaService: VentaService,
		private productosService: ProductosService,
		private modalService: ModalService,
		private util: UtilsService,
		private global: GlobalService,
    public snackvar_service: SnackbarInfoService,
		private initService: InitService
	) {
		this.datosForm = this.formBuilder.group({
			items: this.formBuilder.group({
				codigoPais: [""],
				pais: [""],
				provincia: [""],
			}),
		});

		this.datosClienteForm.emit(this.datosForm);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {

		this.initService.cargado$
			.pipe(
				takeUntil(this.ngUnsubscribe),
				takeWhile(() => !this.cargado)
			)
			.subscribe((cargado) => {
				this.cargado = cargado;
				
				if (this.cargado) {
					this.noEsAdmin = this.productosService.noEsAdministracion();
					this.clientByAdmin = null;
					this.initData();
				}
			});
	}

	initData() {
		forkJoin([
			this.productosService.GetPaisesV2(),
		]).subscribe(([paises]) => {
			if (paises?.DatosPaises && paises?.DatosPaises.length > 0) {
				const paisesResponse = paises.DatosPaises.filter(
					(p) => parseInt(p.Seleccionable) === 1
				);
				this.paisesV2 = paisesResponse.sort(
					(a, b) =>
						parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
				);
			}

			this.userService.currentUser$.subscribe((user) => {
				this.user = user;
				if (!user) {
					if (this.datosForm) {
						Object.keys(
							(this.datosForm.get("items") as FormGroup).controls
						).forEach((control) => {
							(this.datosForm.get("items") as FormGroup)
								.get(control)
								.setValue("");
						});
					}
				}
			});
	
			this.ventaService.enlaceMenuCarrito$
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((em) => {
					if (em) {
						const enlaceMenu = this.productosService.enlacesMenuValue.find(
							(e) => e.pkid === em
						);
						this.ilogueo = enlaceMenu.iLogueo;
						this.loginRegister();
						this.ventaService.userReserva$
							.pipe(takeUntil(this.ngUnsubscribe))
							.subscribe(() => {
								this.cargarCamposDinamicos(enlaceMenu);
							});
					}
				});
		});
	}


	autocompleteForm (campos): void {

		this.clientByAdmin = null;
		const sessionUser = sessionStorage.getItem("currentUser");
		this.user = sessionUser ? JSON.parse(sessionUser) : null;
		const cliente = this.user
			? {
				...this.user,
				...this.ventaService.clienteValue
			}
			: this.ventaService.clienteValue;

		if (cliente?.TipoDocumento) {
			cliente.TipoDocumento = cliente.TipoDocumento.includes("DNI")
				? "DNI"
				: cliente.TipoDocumento;
		}

		if (cliente && !cliente.Provincia) {
			cliente.Provincia = this.provinciaCliente;
		}

		// this.ventaService.setCliente(cliente);

		console.log({
			autocomplete: true,
			cliente,
			user: this.user,
			campos: this.campos
		})

		for (const campo of campos) {
			if (campo.visible) {

				const itemsFormGroup = this.datosForm.get("items");
        if (!itemsFormGroup) {
            console.error('FormGroup "items" is not defined');
            continue;
        }

        if (!campo.name) {
            console.error('Campo name is undefined or null', campo);
            continue;
        }

				switch (campo.name) {
					case "Nombrecontacto":

						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.NombreCliente != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.NombreCliente
									: ""
								: this.user
									? this.noAfiliado(this.user.Afiliado)
										? this.user.NombreClienteOriginal
										: ""
								: "",
						);

						break;
					
					case "nombre":

					this.datosForm.get("items").get(campo.name).setValue(
						cliente && cliente.NombreCliente != ""
							? this.noAfiliado(cliente.Afiliado)
								? cliente.NombreCliente
								: ""
							: this.user
							? this.noAfiliado(cliente.Afiliado)
								? this.user.NombreClienteOriginal
								: ""
							: ""
					);

						break;
					
					case "apellidos":

						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Apellido1 != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Apellido1
									: ""
								: this.user
									? this.noAfiliado(cliente.Afiliado)
										? this.user.PrimerApellidoCliente
										: ""
								: ""
						);

						break;
					
					case "Apellido2Contacto":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Apellido2 != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Apellido2
									: ""
								: this.user
									? this.noAfiliado(cliente.Afiliado)
										? this.user.SegundoApellidoCliente
										: ""
								: ""
						);

						break;
					
					case "emailconfirmacion":
						const valueEmailConfirmacion = cliente
							? this.noAfiliado(cliente.Afiliado)
								?	cliente.EmailConfirmacion
									? cliente.EmailConfirmacion
									: cliente.EMail
										? cliente.EMail
										: ""
								: ""
							: "";
						
						this.datosForm.get("items").get(campo.name).setValue(valueEmailConfirmacion);

						break;
					
					case "email":

						const valueEmail = cliente
							? this.noAfiliado(cliente.Afiliado)
								?	cliente.Email
									? cliente.Email
									: cliente.EMail
										? cliente.EMail
										: cliente.EMail2
											? cliente.EMail2
											: ""
								: ""
							: "";

						this.datosForm.get("items").get(campo.name).setValue(valueEmail);

						break;
					
					case "localidad":
						const localidadValue = cliente
							? this.noAfiliado(cliente.Afiliado)
								? cliente.Localidad
									? cliente.Localidad
									: ""
								: ""
							: "";
						
						this.datosForm.get("items").get(campo.name).setValue(localidadValue);

						break;
					
					case "provincia":
						this.datosForm.get("items").get(campo.name).setValue(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					
					case "nombrecentro":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.NombreContacto != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.NombreContacto
									: ""
								: ""
						);
						break;

					case "nombreresponsable":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.NombreResponsable != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.NombreResponsable
									: ""
								: ""
						);

						break;
					
					case "CIFoDNI":
						const valueCifDni = cliente
							? this.noAfiliado(cliente.Afiliado)
								? cliente.Documento
									? cliente.Documento
									: cliente["CIFoDNI"]
										? cliente["CIFoDNI"]
										: ""
								: ""
							: ""
						this.datosForm.get("items").get(campo.name).setValue(valueCifDni);

						break;
					
					case "documento":
						const valueTipoDocumento = cliente && cliente.TipoDocumento != ""
							? this.noAfiliado(cliente.Afiliado)
								? cliente.TipoDocumento
								: ""
							: "";
						
						const valueNroDocumento = valueTipoDocumento && String(valueTipoDocumento).toLowerCase() !== ""
							? this.noAfiliado(cliente.Afiliado)
								?	cliente["CIFoDNI"]
									? cliente["CIFoDNI"]
									: cliente.Documento
										? cliente.Documento
										: ""
								: ""
							: ""
						this.datosForm.get("items").get(campo.name).setValue(valueNroDocumento);

						break;
					
					case "codigoPostal":

						const valueCodPostal = cliente
							? this.noAfiliado(cliente.Afiliado)
								? cliente.CodigoPostal
									? cliente.CodigoPostal
									: cliente.CP
										? cliente.CP
										: ""
								: ""
							: ""

						this.datosForm.get("items").get(campo.name).setValue(valueCodPostal);

						break;
					
					case "telefono":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Telefono != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Telefono
									: ""
								: ""
						);

						break;

					case "telefonomovil":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Telefono2 != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Telefono2
									: ""
								: ""
						);

						break;
					
					case "direccion":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Direccion != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Direccion
									: ""
								: ""
						);

						break;
					
					case "ciudad":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Ciudad != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Ciudad
									: ""
								: ""
						);

						break;
					
					case "tipoDocumento":
						const valueDocumento = cliente && cliente.TipoDocumento != ""
							? this.noAfiliado(cliente.Afiliado)
								? cliente.TipoDocumento
								: ""
							: "";

						const documentoFind = campo.options.find(
							(o) => String(o).toLowerCase() == String(valueDocumento).toLowerCase()
						);

						this.datosForm.get("items").get(campo.name).setValue(documentoFind ? documentoFind : "");

						break;
					
					case "notas":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Notas != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Notas
									: ""
								: ""
						);

						break;
					
					case "notasinternas":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.NotasInternas != ""
								? cliente.NotasInternas
									? this.noAfiliado(cliente.Afiliado)
										? cliente.NotasInternas
										: ""
									: cliente.ObsAdvertencia
									? this.noAfiliado(cliente.Afiliado)
										? cliente.ObsAdvertencia
										: ""
									: ""
								: ""
						);
						

						break;
					
					case "tipocentro":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.TipoCentro != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.TipoCentro
										? cliente.TipoCentro
										: ""
									: ""
								: ""
						);

						break;
					
					case "curso":
						this.datosForm.get("items").get(campo.name).setValue(
							cliente && cliente.Curso != ""
								? this.noAfiliado(cliente.Afiliado)
									? cliente.Curso
										? cliente.Curso
										: ""
									: ""
								: ""
						);

						break;
					
					case "pais":

						let valuePais = this.paisesV2[0];

						if (cliente) {
							const codPais = cliente.CodigoPais
								? cliente.CodigoPais
								: ""

							const paisFind = this.paisesV2.find((p) => p.CodPais == codPais);
							valuePais = paisFind ? paisFind : this.paisesV2[0];

							console.log('paisFind paisinput::', paisFind)
						}

						console.log('valuePais paisinput::', valuePais)
						this.datosForm.get("items").get(campo.name).setValue(
							valuePais.NombrePais
						);

						this.datosForm.get("items").get("codigoPais").setValue(
							valuePais.idPais
						);

						break;
					default:
						this.datosForm.get("items").get(campo.name).setValue(
							""
						);
						break;
				}
			}
		}

		this.datosClienteForm.emit(this.datosForm);
		this.isLoading = false;
	}

	existCampo(campo: string) {
		if (this.datosForm.get("items").get(campo)) {
			return true;
		}
		return false;
	}

	listenerPais() {
		if (this.existCampo("pais")) {
			this.datosForm.get("items").get("codigoPais").valueChanges
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((pais) => {

					const paisFind = this.paisesV2.find((p) => p.idPais == pais);
					console.log('paisFind listenerPais::', paisFind)
					this.provinciasV2 = [];
					this.ciudadesV2 = [];

					if (this.existCampo("provincia")) {
						this.datosForm.get("items").get("provincia").setValue("");
					}

					if (this.existCampo("ciudad")) {
						this.datosForm.get("items").get("ciudad").setValue("");
					}

					if (paisFind) {
						this.datosForm.get("items").get("pais").setValue(paisFind.NombrePais);
						this.loadProvinciasV2(paisFind.idPais);
					}

				});
		}
	}

	listenerProvincia() {
		if (this.datosForm.get("items").get("provincia")) {
			
			const cliente = this.user ? this.user : this.userService.currentUserValue;

			this.datosForm.get("items").get("provincia").valueChanges
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((provincia) => {

					if (this.provinciasV2.length > 0) {

						const pais = this.existCampo("codigoPais") ? this.datosForm.get("items").get("codigoPais").value : null;

						if (provincia && pais) {
							this.loadCiudadesV2(pais, provincia);
						}
					} else {
						let valueCiudad = ""

						//Setear la ciudad con el valor del cliente
						if (!this.noEsAdmin && this.clientByAdmin) {
							const ciudadNombre = this.clientByAdmin.Ciudad
								? this.clientByAdmin.Ciudad
								: this.clientByAdmin.Localidad
									? this.clientByAdmin.Localidad
									: "";

							valueCiudad = ciudadNombre;
						}

						if (cliente && this.noEsAdmin) {
							const ciudadNombre = cliente.Ciudad
								? cliente.Ciudad
								: cliente.Localidad
									? cliente.Localidad
									: "";

							valueCiudad = ciudadNombre;
						}

						if (this.existCampo("ciudad")) {
							this.datosForm.get("items").get("ciudad").setValue(valueCiudad);
						}
					}
				});
		}
	}

	loginRegister() {
		if (+this.ilogueo > 0) {
			if (this.user == null && !this.userService.userAdminValue) {
				this.modalService.open("LoginAndRegister");
			}
		}
	}

	loadProvinciasV2(paisId: string) {
		const idioma = this.translateService.getLocale();
		const body: HttpBodyGetProvincias = {
			Idioma: idioma,
			idPais: paisId,
			Filtro: "provincia",
		};

		//Si ya hay datos para el idPais seleccionado, no se vuelve a hacer la petición
		const samePais = this.provinciasV2.length > 0 && this.provinciasV2[0].idPais === paisId;
		const sessionUser = sessionStorage.getItem("currentUser");
		this.user = sessionUser ? JSON.parse(sessionUser) : null;
		const cliente = this.user ? this.user : this.userService.currentUserValue;

		if (!samePais) {
			this.productosService.GetProvinciasV2(body).subscribe(
				(res) => {
					if (
						res?.DatosResult?.DatosCiudades &&
						res?.DatosResult?.DatosCiudades.length > 0
					) {

						const provinciasResponse = res.DatosResult.DatosCiudades.filter(
							(p) => parseInt(p.Seleccionable) === 1
						);
						
						this.provinciasV2 = provinciasResponse.sort(
							(a, b) =>
								parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
						);

						let valueProvincia = this.provinciasV2[0];

						if (!this.noEsAdmin && this.clientByAdmin) {
							const provinciaNombre = this.clientByAdmin.Provincia
								? this.clientByAdmin.Provincia
								: "";

							const provinciaFind = this.provinciasV2.find((p) => String(p.NombreProvincia).toLowerCase() == String(provinciaNombre).toLowerCase());

							valueProvincia = provinciaFind ? provinciaFind : valueProvincia;
						}

						if (cliente && this.noEsAdmin) {
							this.clientByAdmin = null;
							const provinciaNombre = cliente.Provincia
								? cliente.Provincia
								: "";

							const provinciaFind = this.provinciasV2.find((p) => String(p.NombreProvincia).toLowerCase() == String(provinciaNombre).toLowerCase());

							valueProvincia = provinciaFind ? provinciaFind : this.provinciasV2[0];

						}
						
						this.lastSelectedProvincia = valueProvincia.CodProvincia;

						if (this.existCampo("provincia")) {
							this.datosForm.get("items").get("provincia").setValue(valueProvincia.CodProvincia);
						}
						

					} else {
						this.provinciasV2 = [];
						this.ciudadesV2 = [];
						this.lastSelectedProvincia = "";
						if (this.existCampo("provincia")) {
							this.datosForm.get("items").get("provincia").setValue(cliente.Provincia ? cliente.Provincia : "");
						}
					}
				},
				(error) => {
					console.log({
						errorLoadProvincias: error,
					});
					this.provinciasV2 = [];
					this.ciudadesV2 = [];
					this.lastSelectedProvincia = "";
					if (this.existCampo("provincia")) {
						this.datosForm.get("items").get("provincia").setValue(cliente.Provincia ? cliente.Provincia : "");
					}
				}
			);
		}

		this.lastSelectedCodigoPais = paisId;
	}

	loadCiudadesV2(paisId: string, codProvincia: string) {
		const idioma = this.translateService.getLocale();
		const body: HttpBodyGetProvincias = {
			Idioma: idioma,
			idPais: paisId,
			CodProvincia: codProvincia,
			Filtro: "ciudad",
		};

		const sessionUser = sessionStorage.getItem("currentUser");
		this.user = sessionUser ? JSON.parse(sessionUser) : null;
		const cliente = this.user ? this.user : this.userService.currentUserValue;

		this.productosService.GetProvinciasV2(body).subscribe(
			(res) => {
				if (
					res?.DatosResult?.DatosCiudades &&
					res?.DatosResult?.DatosCiudades.length > 0
				) {
					const ciudadesResponse = res?.DatosResult?.DatosCiudades.filter(
						(p) => parseInt(p.Seleccionable) === 1
					);
					this.ciudadesV2 = ciudadesResponse.sort(
						(a, b) =>
							parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
					);

					console.log({
						ciudades: this.ciudadesV2
					})

					let valueCiudad = this.ciudadesV2[0];

					if (!this.noEsAdmin && this.clientByAdmin) {
						const ciudadNombre = this.clientByAdmin.Ciudad
							? this.clientByAdmin.Ciudad
							: "";

						const ciudadFind = this.ciudadesV2.find((c) => String(c.NombreCiudad).toLowerCase() == String(ciudadNombre).toLowerCase());

						valueCiudad = ciudadFind ? ciudadFind : valueCiudad;
					}

					if (cliente && this.noEsAdmin) {
						this.clientByAdmin = null;
						const ciudadNombre = cliente.Ciudad
							? cliente.Ciudad
							: cliente.Localidad
								? cliente.Localidad
								: "";

						const ciudadFind = this.ciudadesV2.find((c) => String(c.NombreCiudad).toLowerCase() == String(ciudadNombre).toLowerCase());

						valueCiudad = ciudadFind ? ciudadFind : this.ciudadesV2[0];
					}

					if (this.existCampo("ciudad")) {
						this.datosForm.get("items").get("ciudad").setValue(valueCiudad.CodCiudad);
					}

				} else {
					this.ciudadesV2 = [];
					const valueInputCiudad = cliente.Ciudad ? cliente.Ciudad : cliente.Localidad ? cliente.Localidad : "";
					console.log({
						valueInputCiudad
					})
					if (this.existCampo("ciudad")) {
						this.datosForm.get("items").get("ciudad").setValue(valueInputCiudad);
					}
				}
			},
			(error) => {
				console.log({
					errorLoadCiudades: error,
				});
				this.ciudadesV2 = [];
				const valueInputCiudad = cliente.Ciudad ? cliente.Ciudad : cliente.Localidad ? cliente.Localidad : "";
				if (this.existCampo("ciudad")) {
					this.datosForm.get("items").get("ciudad").setValue(valueInputCiudad);
				}
			}
		);
	}

	get p() {
		return this.datosForm.controls;
	}

	getControls(datosForm: FormGroup) {
		return <FormArray>datosForm.controls.items;
	}

	cargarCamposDinamicos(enlaceMenu) {
		if (JSON.parse(sessionStorage.getItem("cliente"))) {
			this.ventaService.setCliente(
				JSON.parse(sessionStorage.getItem("cliente"))
			);
		}
		this.campos = JSON.parse(
			enlaceMenu.CaracteristicasAdicionales
		).CaracteristicasAdicionales.DatosCompra;

		console.log({
			enlaceMenu,
			campos: this.campos,
		});

		// Los campos "poblacion", "ciudad" y "localidad" son lo mismo
		// Solo se manejará el campo ciudad
		if (this.campos && this.campos.length > 0) {
			this.campos = this.campos.map(
				(c) => {
					if (c.name === "poblacion" || c.name === "localidad") {
						c.name = "ciudad";
					}
					return c;
				}
			);

			// Dejar solo un campo con el nombre "ciudad"
			this.campos = this.campos.filter(
				(c, index, self) =>
					index === self.findIndex((t) => t.name === c.name)
			);
		}


		if (this.campos.find((campo) => campo.busqueda)) {
			this.buscador();
		}
		this.datosForm = this.formBuilder.group({
			items: this.createFormItems(this.campos),
		});

		const a = this.getControls(this.datosForm);
		const controls: any = a.controls;
		if (controls.tipoDocumento) {
			controls.tipoDocumento.valueChanges
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((checked) => {
					if (checked) {
						controls.documento.setValidators([
							Validators.required,
							Validators.pattern(
								this.documentoValidar(controls.tipoDocumento.value)
							),
							this.ValidarDocNieDni("tipoDocumento"),
						]);
					}
				});
		}

		this.datosClienteForm.emit(this.datosForm);
		this.datosForm.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				let procedenviaValue: ProcedenciaValues = {
					Pais: "",
					CodigoPais: "",
					ProvinciaContacto: "",
					CodProvincia: "",
					CodCiudad: "",
					Ciudad: "",
				};

				let paisValue = null;
				let provinciaValue = null;
				let ciudadValue = null;

				if (this.paisesV2.length > 0 && this.existCampo("pais")) {
					const pais = this.datosForm.get("items").get("codigoPais").value;
					const paisFind = this.paisesV2.find((p) => p.idPais == pais);
					
					paisValue = paisFind ? paisFind : null;
				}

				if (this.provinciasV2.length > 0 && this.existCampo("provincia")) {
					const provincia = this.datosForm.get("items").get("provincia").value;
					const provinciaFind = this.provinciasV2.find((p) => p.CodProvincia == provincia);
					
					provinciaValue = provinciaFind ? provinciaFind : null;
				}

				if (this.ciudadesV2.length > 0 && this.existCampo("ciudad")) {
					const ciudad = this.datosForm.get("items").get("ciudad").value;
					const ciudadFind = this.ciudadesV2.find((c) => c.CodCiudad == ciudad);
					
					ciudadValue = ciudadFind ? ciudadFind : null;
				}

				if (this.provinciasV2.length === 0 && this.existCampo("provincia")) {
					provinciaValue = {
						NombreProvincia: this.datosForm.get("items").get("provincia").value,
						CodProvincia: "",
					}
				}

				if (this.ciudadesV2.length === 0 && this.existCampo("ciudad")) {
					ciudadValue = {
						NombreCiudad: this.datosForm.get("items").get("ciudad").value,
						CodCiudad: "",
					}
				}

				procedenviaValue = {
					Pais: paisValue ? paisValue.NombrePais : "",
					CodigoPais: paisValue ? paisValue.CodPais : "",
					ProvinciaContacto: provinciaValue ? provinciaValue.NombreProvincia : "",
					CodProvincia: provinciaValue ? provinciaValue.CodProvincia : "",
					CodCiudad: ciudadValue ? ciudadValue.CodCiudad : "",
					Ciudad: ciudadValue ? ciudadValue.NombreCiudad : "",
				};

				this.datosClienteForm.emit(this.datosForm)
				this.datosProcedenciaForm.emit(procedenviaValue);
			});

		this.listenerPais();
		this.listenerProvincia();
		this.autocompleteForm(this.campos);
	}

	documentoValidar(tipoDocumento: string) {
		const control = tipoDocumento;
		const nifReg = /^(\d{8})([A-Z]|[a-z])$/;
		const nieReg = /^[XYZ][0-9]{7}[A-Z]$/;
		if (control === "NIE") {
			return nieReg;
		}
		if (control === "DNI") {
			return nifReg;
		}
		if (control === "Otro") {
			return null;
		}
	}

	ValidarDocumento(tipoDocumento: string, numeroDocumento: string) {
		let numValido = true;
		const control = tipoDocumento;
		if (control === "NIE") {
			numValido = this.global.VerificarNIE(numeroDocumento);
		}
		if (control === "DNI") {
			numValido = this.global.VerificarDNI(numeroDocumento);
		}
		if (control === "CIF") {
			numValido = this.global.VerificarCIF(numeroDocumento);
		}
		return numValido;
	}

	repetirValidar(otherControlName: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const a = control.root["controls"];
			let otherControl: AbstractControl;
			if (a) {
				otherControl = a.items.get(otherControlName);
			}
			if (otherControl) {
				const subscription: Subscription = otherControl.valueChanges.subscribe(
					() => {
						control.updateValueAndValidity();
						subscription.unsubscribe();
					}
				);
			}

			let control1 = control
				? control.value == undefined
					? ""
					: control.value
				: "";
			let control2 = otherControl
				? otherControl.value == undefined
					? ""
					: otherControl.value
				: "";
			return control && control1.toLowerCase() !== control2.toLowerCase()
				? { match: true }
				: null;
		};
	}

	ValidarDocNieDni(otherControlName: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const a = control.root["controls"];
			let otherControl: AbstractControl;
			if (a) {
				otherControl = a.items.get(otherControlName);
			}
			if (otherControl) {
				const subscription: Subscription = otherControl.valueChanges.subscribe(
					() => {
						control.updateValueAndValidity();
						subscription.unsubscribe();
					}
				);
			}

			let documento = control
				? control.value == undefined
					? ""
					: control.value
				: "";
			let tipoDocumento = otherControl
				? otherControl.value == undefined
					? ""
					: otherControl.value
				: "";
			return !this.ValidarDocumento(tipoDocumento, documento)
				? { match: true }
				: null;
		};
	}

	noAfiliado(campo: string = "") {
		return !campo || campo == "0";
	}

	createFormItems(campos): FormGroup {
		const group = {};
		for (const campo of campos) {
			if (campo.visible) {
				switch (campo.name) {
					case "Nombrecontacto":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [Validators.required, Validators.pattern("^[A-zÀ-ÿ.\\s]*$")]
								: Validators.pattern("^[A-zÀ-ÿ.\\s]*$")
						);
						break;
					case "nombre":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s]*$")]
								: Validators.pattern("^[A-zÀ-ÿ\\s]*$")
						);
						break;
					case "apellidos":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s]*$")]
								: Validators.pattern("^[A-zÀ-ÿ\\s]*$")
						);
						break;
					case "Apellido2Contacto":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s]*$")]
								: Validators.pattern("^[A-zÀ-ÿ\\s]*$")
						);
						break;
					case "emailconfirmacion":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [
									Validators.required,
									Validators.pattern(
										"^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$"
									),
									this.repetirValidar("email"),
								]
								: [
									Validators.pattern(
										"^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$"
									),
									this.repetirValidar("email"),
								]
						);
						break;
					case "email":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [
									Validators.required,
									Validators.pattern(
										"^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$"
									),
								]
								: [
									Validators.pattern(
										"^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$"
									)
								]
						);
						break;
					case "localidad":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "provincia":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "nombrecentro":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "nombreresponsable":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "CIFoDNI":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [Validators.required, this.ValidarDocNieDni("tipoDocumento")]
								: [
									Validators.nullValidator,
									this.ValidarDocNieDni("tipoDocumento"),
								]
						);
						break;
					case "documento":
						group[campo.name] = new FormControl(
							"",
							campo.required
								? [
									Validators.required,
									this.ValidarDocNieDni("tipoDocumento")
								]
								: [
									Validators.nullValidator,
									this.ValidarDocNieDni("tipoDocumento"),
								]
						);
						break;
					case "codigoPostal":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "telefono":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "telefonomovil":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "direccion":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "ciudad":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "tipoDocumento":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "notas":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "notasinternas":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "tipocentro":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "curso":
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
					case "pais":
						
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);

						group["codigoPais"] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);

						break;
					default:
						group[campo.name] = new FormControl(
							"",
							campo.required ? Validators.required : Validators.nullValidator
						);
						break;
				}
			}
		}
		const apiFormGroup = new FormGroup(group);
		return apiFormGroup;
	}

	campoTipo(chTipoControl: string) {
		switch (chTipoControl) {
			case "radiobutton":
				return "dropDown";
			case "select":
				return "dropDown";
			case "dropDown":
				return "dropDown";
			case "input":
				return "text";
			default:
				return "unknown";
		}
	}

	abrirBuscador(campo) {
		this.abrir = [];
		this.abrir.push({ campo, abierto: true });
		this.clientes = [];
		this.setPage(1);
	}

	estaAbierto(campo) {
		let abierto = false;
		if (this.abrir.length) {
			const campoAbierto = this.abrir.find((c) => campo === c.campo);
			if (campoAbierto) {
				abierto = true;
			}
			return abierto;
		} else {
			return false;
		}
	}

	textoBuscar(texto, campo) {
		this.modelChanged.next(texto);
		this.campo = campo;
	}

	buscador() {
		this.modelChanged
			//.pipe(filter((res: any) => res.length > 2))
			.pipe(debounceTime(500))
			.pipe(
				exhaustMap((input) => this.userService.getClientes(input, this.campo))
			)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((res: any) => {
				if (res) {
					if (res.length) {
						this.clientes = res;
						this.totalPages = Math.ceil(this.clientes.length / 5);
						this.setPage(1);
					} else {
						this.clientes = [];
						this.setPage(1);
					}
				} else {
					this.clientes = [];
					this.setPage(1);
				}
			});
	}

	setPage(page: number) {
		this.pager = this.pagerService.getPager(this.clientes.length, page);
		this.pagedItems = this.clientes.slice(
			this.pager.startIndex,
			this.pager.endIndex + 1
		);
		this.pager.pages = this.pager.pages.filter(
			(num) => num <= this.pager.totalPages
		);
		if (page > 6) {
			while (this.pager.pages.length < 6) {
				this.pager.pages.unshift(this.pager.pages[0] - 1);
			}
		}
	}

	anterior() {
		this.setPage(this.pager.currentPage - 1);
	}

	siguiente() {
		this.setPage(this.pager.currentPage + 1);
	}

	seleccionarCliente(cliente) {
		this.ventaService.mapCliente(cliente);
		const clienteModel = this.ventaService.clienteValue;
		this.clientByAdmin = clienteModel;
		const a = this.getControls(this.datosForm);
		const controls: any = a.controls;
		controls.nombrecentro.patchValue(clienteModel.NombreContacto);
		controls.nombreresponsable.patchValue(clienteModel.NombreResponsable);
		controls.email.patchValue(clienteModel.Email);
		controls.ciudad.patchValue(clienteModel.Ciudad);
		controls.direccion.patchValue(clienteModel.Direccion);
		controls.codigoPostal.patchValue(clienteModel.CodigoPostal);
		controls.telefono.patchValue(clienteModel.Telefono);
		controls.CIFoDNI.patchValue(clienteModel.Documento);
		controls.notasinternas.patchValue(clienteModel.NotasInternas);

		let valuePais = this.paisesV2[0];

		if (clienteModel) {
			const codPais = clienteModel.CodigoPais
				? clienteModel.CodigoPais
				: "";

			const paisFind = this.paisesV2.find((p) => p.CodPais == codPais);
			valuePais = paisFind ? paisFind : this.paisesV2[0];
		}

		controls.pais.patchValue(
			valuePais.NombrePais
		);

		controls.codigoPais.patchValue(
			valuePais.idPais
		);
		
		if (controls.tipocentro) {
			controls.tipocentro.patchValue(clienteModel.TipoCentro);
		}
		controls.apellidos.patchValue(clienteModel.PrimerApellidoCliente);
		this.focusOut();
		this.abrir = [];
	}

	focusOut() {
		const clienteGuardado = this.ventaService.clienteValue;
		const cliente = { ...this.datosForm.value.items };
		console.log({
			cliente: {
				...this.datosForm.value.items,
			},
		});
		let nombre;
		let apellidos;
		let apellido1;
		let apellido2;
		let user;
		let password;
		let afiliado;
		let tipopagonombre;

		if (clienteGuardado) {
			nombre = cliente.Nombrecontacto
				? cliente.Nombrecontacto
				: clienteGuardado.NombreClienteAdicional
				? clienteGuardado.NombreClienteAdicional
				: clienteGuardado.NombreCliente;
			apellidos = cliente.apellidos
				? cliente.apellidos
				: clienteGuardado.Apellidos;
			apellido1 = cliente.apellidos
				? cliente.apellidos
				: clienteGuardado.Apellido1;
			apellido2 = cliente.Apellido2Contacto
				? cliente.Apellido2Contacto
				: clienteGuardado.Apellido2;
			user = clienteGuardado.User ? clienteGuardado.User : null;
			password = clienteGuardado.Pass ? clienteGuardado.Pass : null;
			afiliado = clienteGuardado.Afiliado ? clienteGuardado.Afiliado : "";
			tipopagonombre = clienteGuardado.TipoPagoNombre
				? clienteGuardado.TipoPagoNombre
				: "";
		} else {
			nombre = cliente.Nombrecontacto ? cliente.Nombrecontacto : "";
			apellidos = cliente.apellidos ? cliente.apellidos : "";
			apellido1 = cliente.apellidos ? cliente.apellidos : "";
			apellido2 = cliente.Apellido2Contacto ? cliente.Apellido2Contacto : "";
			afiliado = cliente.Afiliado ? cliente.Afiliado : "";
			tipopagonombre = cliente.TipoPagoNombre ? cliente.TipoPagoNombre : "";
		}

		const newClient: Cliente = {
			User: user,
			Pass: password,
			Documento: cliente.CIFoDNI ? cliente.CIFoDNI : cliente.documento,
			Ciudad: cliente.ciudad,
			CodigoPostal: cliente.codigoPostal,
			Direccion: cliente.direccion,
			Email: cliente.email,
			EmailConfirmacion: cliente.emailconfirmacion,
			NombreCliente: cliente.nombre ? cliente.nombre : nombre,
			NombreContacto: cliente.nombrecentro,
			NombreResponsable: cliente.nombreresponsable,
			// manieva 11520
			// cuando el atributo de nombre no esta disponible usamos el de user
			// sino este valor se pierde y es necesario para otras flujos
			Nombre: cliente.nombre,
			Notas: cliente.notas,
			NotasInternas: cliente.notasinternas ? cliente.notasinternas : "",
			Telefono: cliente.telefono,
			Apellidos: apellidos,
			Apellido1: apellido1,
			Apellido2: apellido2,
			Pais: cliente.pais,
			CodigoPais: cliente.codigoPais,
			TipoDocumento: cliente.tipoDocumento,
			Telefono2: cliente.telefonomovil,
			TipoCentro: cliente.tipocentro,
			Curso: cliente.curso,
			Provincia: cliente.provincia,
			Publicidad: undefined,
			Privacidad: undefined,
			Afiliado: afiliado,
			TipoPagoNombre: tipopagonombre,
		};
		this.ventaService.setCliente(newClient);
		//sessionStorage.setItem('cliente', JSON.stringify(newClient));
	}

	nombreCampo(nombreCampo) {
		return this.util.getPlaceHolder(nombreCampo);
	}
}
